import request from '@/utils/request'

export function getRecordsList(params) {
    return request({
      url: `/pc/flowable/archive`,
      method: 'GET',
      params
    })
  }
  export function getRecordsDetail(params) {
    return request({
      url: `/pc/flowable/detail/${params}`,
      method: 'GET',
    })
  }
  export function delRecords(params) {
    return request({
      url: `/pc/flowable/archive`,
      method: 'DELETE',
      params
    })
  }
  export function statusRecords(status,processId) {
    return request({
      url: `/pc/flowable/archive/status?processId=${processId}&status=${status}`,
      method: 'PUT',

    })
  }
  export function changeRecords(params) {
    return request({
      url: `/pc/flowable/archive`,
      method: 'PUT',
      data:params
    })
  }
  export function addRecordsProcess(data) {
    return request({
      url: `/pc/flowable/archive`,
      method: 'POST',
      data
    })
  }
  export function draftRecordsProcess(data) {
    return request({
      url: `/pc/flowable/archive/draft`,
      method: 'POST',
      data
    })
  }